import React, { useState } from "react";
import * as styles from "./ContactForm.module.scss";
import { Link } from "gatsby";
import { externalURL } from "../../config/url";

import * as Yup from "yup";
import Wizard from "./Wizard";
import { Col, Row } from "../layout/Grid";
import ScrollReveal from "../layout/ScrollReveal";
import classNames from "classnames";
import InputField from "./InputField";

import LinkedIn from "../../images/linkedin-logo.svg";

const WizardStep = ({ children }) => children;

const ContactForm = () => {
  const [fieldNumber, setFieldNumber] = useState(1);
  return (
    <Row>
      <Col width="40%">
        <ScrollReveal effect="slide-right" delay={50}>
          <h3 className="h3">
            Interested in working with us? Simply fill out the form
          </h3>
          <Link
            to={externalURL.linkedin}
            target="_blank"
            className={styles.social}
          >
            <img src={LinkedIn} alt="LinkedIn" />
            Simplicitic
          </Link>
        </ScrollReveal>
      </Col>
      <Col width="50%">
        <ScrollReveal effect="slide-left" delay={50}>
          <ul className={styles.nav}>
            <li className={classNames(fieldNumber >= 1 && styles.active)} />
            <li className={classNames(fieldNumber >= 2 && styles.active)} />
            <li className={classNames(fieldNumber >= 3 && styles.active)} />
            <li className={classNames(fieldNumber >= 4 && styles.active)} />
            <li className={classNames(fieldNumber >= 5 && styles.active)} />
            <li className={classNames(fieldNumber >= 6 && styles.active)} />
            {/* <li className={classNames(fieldNumber >= 7 && styles.active)} /> */}
          </ul>
          <Wizard
            initialValues={{
              name: "",
              email: "",
              phone: "",
              companyName: "",
              subject: "",
              message: "",
              reference: "",
            }}
          >
            <WizardStep
              onSubmit={() => setFieldNumber(2)}
              validationSchema={Yup.object({
                name: Yup.string().required("Please fill in your name"),
              })}
            >
              <label htmlFor="name" className={styles["label"]}>
                Hi, what's your name?
              </label>
              <InputField
                id="name"
                inputName="name"
                placeholder="John Smith"
                autoFocus
              />
            </WizardStep>
            <WizardStep
              onSubmit={() => setFieldNumber(3)}
              validationSchema={Yup.object({
                phone: Yup.string()
                  .required("Please fill in your contact number")
                  .matches(/^[0-9]+$/, "Please enter digits only")
                  .min(10, "Please enter a valid contact number")
                  .max(12, "Please enter a valid contact number"),
              })}
            >
              <label htmlFor="phone" className={styles["label"]}>
                How can we contact you?
              </label>
              <InputField
                id="phone"
                inputName="phone"
                placeholder="0123456789"
                autoFocus
              />
            </WizardStep>
            <WizardStep
              onSubmit={() => setFieldNumber(4)}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Please fill in your email address")
                  .email("Please enter a valid email"),
              })}
            >
              <label htmlFor="email" className={styles["label"]}>
                What's your email address?
              </label>
              <InputField
                id="email"
                inputName="email"
                placeholder="johnsmith@example.com"
                type="email"
                autoFocus
              />
            </WizardStep>

            <WizardStep
              onSubmit={() => setFieldNumber(5)}
              validationSchema={Yup.object({
                companyName: Yup.string().required(
                  "Please fill in your company name"
                ),
              })}
            >
              <label htmlFor="companyName" className={styles["label"]}>
                What's your company name?
              </label>
              <InputField
                id="companyName"
                inputName="companyName"
                placeholder="Company / Organisation"
                autoFocus
              />
            </WizardStep>
            <WizardStep
              onSubmit={() => setFieldNumber(6)}
              validationSchema={Yup.object({
                subject: Yup.string().required("Please enter your subject"),
              })}
            >
              <label htmlFor="subject" className={styles["label"]}>
                I'm Interested in...
              </label>
              <InputField
                id="subject"
                inputName="subject"
                placeholder="Subject"
                autoFocus
              />
            </WizardStep>
            <WizardStep
              // onSubmit={() => setFieldNumber(7)}
              onSubmit={() => {
                console.log("form onSubmit");
              }}
              validationSchema={Yup.object({
                message: Yup.string().required("Please enter your message"),
              })}
            >
              <label htmlFor="message" className={styles["label"]}>
                How can we help?
              </label>
              <InputField
                component="textarea"
                id="message"
                inputName="message"
                placeholder="Your message"
              />
            </WizardStep>
            {/* <WizardStep
              onSubmit={() => {
                console.log("form onSubmit");
              }}
            >
              <label htmlFor="reference" className={styles["label"]}>
                Any references?
              </label>
              <InputField
                component="textarea"
                id="reference"
                inputName="reference"
                placeholder="[opt.]"
              />
              <ErrorMessage
                className="msg-error"
                component="div"
                name="reference"
              />
            </WizardStep> */}
          </Wizard>
        </ScrollReveal>
      </Col>
    </Row>
  );
};

export default ContactForm;
