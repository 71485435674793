import React, { useState, useEffect } from "react";
import * as styles from "./Wizard.module.scss";
import { Form, Formik } from "formik";
import axios from "axios";
import {
  loadRecaptcha,
  removeRecaptcha,
  getRecaptchaToken,
} from "../../utils/googleRecaptcha";

const Wizard = ({ children, initialValues }) => {
  const [loading, setLoading] = useState(false);
  const [responseMsg, setResponseMsg] = useState();
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  useEffect(() => {
    loadRecaptcha();
    return () => removeRecaptcha();
  }, []);

  return (
    <Formik
      initialValues={snapshot}
      validationSchema={step.props.validationSchema}
      onSubmit={async (values, bag) => {
        if (step.props.onSubmit) {
          await step.props.onSubmit(values, bag);
        }
        if (isLastStep) {
          setLoading(true);
          setResponseMsg();
          try {
            const recaptchaCode = await getRecaptchaToken("contact_form");
            const options = {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              // data: qs.stringify({ ...values, token: recaptchaCode }),
              data: { ...values, token: recaptchaCode },

              url: "/.netlify/functions/form-submit",
              withCredentials: false,
            };
            console.log(values);
            const response = await axios(options);
            console.log("response", response);
            setResponseMsg({
              success:
                "Your email has been sent. We will get back to you in one working day.",
            });
            bag.setSubmitting(false);
            // setMsgSent("Email sent");
            bag.resetForm();
          } catch (err) {
            console.log("err", err);
            let errorMsg = "Error occurred. Pease try again";

            if (err.response) {
              console.log("err response", err.response);
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              errorMsg = err.response.data.message;
            } else if (err.request) {
              console.log("err request", err.request);
              // The request was made but no response was received
              // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              errorMsg = err.request;
            } else {
              console.log("err message", err.message);
              // Something happened in setting up the request that triggered an Error
              errorMsg = err.message;
            }
            console.log("errorMsg", errorMsg);
            setResponseMsg({ error: errorMsg });
          }
          setLoading(false);
        } else {
          bag.setTouched({});
          next(values);
        }
      }}
    >
      {() => (
        <Form>
          <div className={styles["input_group"]}>{step}</div>
          <div className={styles["button"]}>
            {isLastStep ? (
              <>
                <button disabled={loading} type="submit" className="btn">
                  Submit
                </button>
                {responseMsg && (
                  <p className="response_msg">
                    {responseMsg.error && (
                      <span className="error_msg">{responseMsg.error}</span>
                    )}
                    {responseMsg.success && (
                      <span className="success_msg">{responseMsg.success}</span>
                    )}
                  </p>
                )}
                {loading ? <div className="loading"></div> : ""}
              </>
            ) : (
              <button
                disabled={Formik.isSubmitting}
                type="submit"
                className="btn"
              >
                Continue
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Wizard;
