import React from "react";
import * as styles from "./contact.module.scss";
import PageLayout from "../components/layout/PageLayout";
import Masthead from "../components/layout/Masthead";
import Seo from "../components/layout/SEO";
import Intro from "../components/layout/Intro";
import ScrollReveal from "../components/layout/ScrollReveal";
import ContactLocation from "../components/contact/ContactLocation";
// import ContactForm from "../components/contact/ContactForm";

import TrainStation from "../images/train-icon-white.svg";
import contactAnim from "../assets/json/contact.json";

const contact = () => {
  return (
    <PageLayout anim={contactAnim}>
      <Seo title="Contact - Simplicitic Innovations Sdn Bhd" />
      <Masthead
        title={
          <>
            Hello, Apa Khabar <b className="chinese-character">你好</b>
          </>
        }
        tag="contact us"
      />
      <Intro>
        <ScrollReveal effect="slide-up" delay={50}>
          <div className={`text-center ${styles["address"]}`}>
            <h3 className="h2">Reach out to us</h3>
            <address>
              <p>
                Unit 2-1, Level 2, The Podium, Tower 3, UOA Business Park,
                <br />
                No. 1, Jalan Pengaturcara U1/51A, Seksyen U1,
                <br />
                40150 Shah Alam, Selangor Darul Ehsan.
              </p>
            </address>
            <div className={styles.train}>
              <img src={TrainStation} alt="Train Station" />
              Next to Subang Jaya LRT/KTM Station
            </div>
          </div>
        </ScrollReveal>
      </Intro>
      <ContactLocation />
    </PageLayout>
  );
};

export default contact;
