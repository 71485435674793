import React, { useState, useRef, useEffect } from "react";
import * as styles from "./ContactLocation.module.scss";
import VectorMap from "../../images/map.svg";
import IconMap from "../../images/map-icon.svg";
import ContactForm from "./ContactForm";
import classNames from "classnames";

const ContactLocation = () => {
  const [enter, setEnter] = useState(false);
  const inputRef = useRef();

  const scrollHandler = () => {
    if (inputRef.current.getBoundingClientRect().top <= 350) {
      setEnter(true);
    }
  };

  useEffect(() => {
    typeof window !== "undefined" &&
      window.addEventListener("scroll", scrollHandler, true);
    return () => {
      typeof window !== "undefined" &&
        window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);
  return (
    <>
      <section>
        <div className={`section wrapper ${styles.container}`}>
          <div className="title">Contact</div>
          <ContactForm />
          {/* <ScrollReveal effect="slide-up" delay={50}> */}
          <div
            className={classNames(styles["map"], enter && styles["entered"])}
            ref={inputRef}
          >
            <img src={IconMap} alt="Vector Map" className={styles.mapIcon} />
            <img src={VectorMap} alt="Vector Map" />
          </div>
          {/* </ScrollReveal> */}
        </div>
      </section>
      <div className={styles["googlemap"]}>
        <iframe
          src="https://snazzymaps.com/embed/351169"
          title="Simplicitic Innovations"
        />
      </div>
    </>
  );
};

export default ContactLocation;
